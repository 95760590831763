import React, { useEffect } from 'react';
import '../pages/About.css';
import { GlobalMethods } from '../services/GlobalMethods';
import SendEmail from '../components/utils/SendEmail';
import MetaTags from '../components/utils/MetaTags';


export default function About() {
    const methods = new GlobalMethods()
    GlobalMethods.current_sec = "about-us-section";
    useEffect(() => {
        setTimeout(() => {
            methods.snapTo(GlobalMethods.current_sec)
        }, 500);
    }, [])
    return (
        <>
            <MetaTags text="MyFitnessCoach | About Us" />
            <main className="px-responsive-5" >
                <section className='founder' id="about-us-section" >
                    <div className='about-sec2-title d-flex flex-column align-items-center'>
                        <h2 className='hero-sub-heading text-center'>
                            Founder’s Journey – Empowering the Future of Wellness
                        </h2>
                        <div className='ceo-img'>
                            <h4 className='f-20'>Transforming Health Together: The Father-Son Duo Behind a Global Wellness Revolution.</h4>
                            <img  className='w-100' src='../assets/zz-image.jpg' alt='Ceo of myfitnesscoach app: father and Son (Zeeshan zakaria & Zaid Zakaria)' />
                            <p>Zeeshan Zakaria(Father) & Zaid Zakaria(Son)</p>
                        </div>
                        <div className='about-description2 w-100 d-flex align-items-start mt-3'>
                            <p className='plan-txt-sm-black text-start'>
                            When I was a child, I would watch my friends play outside, laughing and enjoying the sunshine, but more often than not, I couldn’t join them. Some days, even getting out of bed felt impossible, let alone studying for exams or staying active. For as long as I can remember, I’ve battled severe migraines—days spent in darkness, helpless, waiting for the pain to fade. I sought help from top doctors in Pakistan, London, and Dubai and tried everything from acupuncture to gluten-free and dairy-free diets, but nothing seemed to work.
                            <br/>
                            <br/>
                            As I grew older, the frequency of my migraines reduced, but the experience left a deep impression on me. I knew what it felt like to be powerless, and I wanted to do something to help others avoid that feeling. That was the spark that ignited my journey into health and wellness. During the COVID-19 lockdowns, I noticed something remarkable: the air quality in Lahore had improved dramatically. In that moment of clarity, I had an epiphany—what if I could create something that harnessed technology to improve people’s lives, just as cleaner air had improved mine?
                            <br/>
                            <br/>
                            That realization marked the beginning of my wellness app journey. However, before I could bring my vision to life, I needed to take the first step: convincing my father (and now also my co-founder) to invest in the idea. My father, Zeeshan Zakaria, is a serial entrepreneur with two successful companies under his belt. I knew convincing him wouldn’t be easy. Passion alone wouldn’t cut it; I needed a solid business case. So, I threw myself into research—analyzing market size, studying the competition, and projecting future growth prospects. I poured everything into crafting a detailed business plan, and after weeks of discussions, my father finally agreed to back the project.
                            <br/>
                            <br/>
                            We started development in 2022, and over the next year, I was deeply involved in every aspect. I acted as the bridge between our test users and the development team, constantly collecting feedback, iterating, and refining the app. It was an intense and challenging process, but every step forward brought us closer to our goal. After a year of relentless effort, we launched the app on the Google and Apple App Stores in February 2023. It was a surreal moment—the beginning of my lifelong mission to enhance health and wellness for people around the globe.
                            <br/>
                            <br/>
                            As I continue on this journey, I’m eager to hear from you about how my team and I can make the app even better. Please feel free to drop me a note at zaid&#10098;&#64;&#10099;myfitnesscoach.fit. I look forward to connecting with you and learning how we can improve together.
                            </p>
                        </div>
                    </div>
                </section>
                <section className='about-who-we-are mt-5rem'>
                    <div className='about-sec1-title d-flex flex-column align-items-center'>
                        <h2 className='hero-sub-heading text-center'>
                            Who We Are
                        </h2>
                        <div className='about-description w-50 d-flex align-items-center'>
                            <p className='plan-txt-sm-black text-center'>
                                <a href='https://myfitnesscoach.fit/' target='_blank'> MyFitnessCoach</a> is your personal trainer to help you achieve better health and a fitter life as quickly and effortlessly as possible. We make your goals our goal!</p>
                        </div>
                    </div>
                    <div className='about-body1-wraper d-flex align-items-center justify-content-center pt-5 cg'>
                        <div className='about-left col-lg-6 col-md-12'>
                            <div className='left-top d-flex flex-column'>
                                <h2 className='card-inner-heading'>
                                    Our Mission And  Vision
                                </h2>
                                <span className='stepper-row-line'></span>
                                <p className='plan-txt-md pt-4'>At MyFitnessCoach, our mission and vision are simple: empower individuals to take charge of their health through fitness, nutrition, and healthy lifestyles. <br />
                                    Our belief is that all individuals from any background and fitness level deserve access to tailored fitness tools.
                                </p>
                            </div>
                            <div className='left-bottom pt-5'>
                                <button type='button' className='btn btn-outline-primary about-sec1-button'
                                    onClick={() => { document.querySelector("#subscription-section").scrollIntoView(true) }}>Get in touch</button>
                            </div>
                        </div>
                        <div className='about-right col-lg-6 col-md-12 '>
                            <img  className='w-100' src='../assets/about-hero.webp' alt='girl doing workout' />
                        </div>
                    </div>
                </section>
                <section id="subscription-section" className='subscription d-flex align-items-center mt-5rem'>
                    <div className='subs-left pe-5 d-flex flex-column rg'>
                        <h2 className='subscribe-heading'>Have Something In Mind</h2>
                        <p className='subscribe-paragraph'>Contact us if you have any queries regarding our app or would like to learn more about our fitness and diet plans. Please contact us and we'll be more than happy to assist you.</p>
                        <span className='pt-5'></span>
                        <SendEmail />
                    </div>
                    <div className='subs-right d-flex justify-content-center'>
                        <img  src='../assets/envelope-email.webp' alt='blue envelope used for subscription' />
                    </div>
                </section>
            </main>
        </>
    )
}